.help-button {
    position: relative;
    padding-left: 32px;
    padding-right: 20px;
    background: #4cb6d8;
}

.help-button::before {
    content: url('./help.svg');
    width: 18px;
    height: 18px;
    position: absolute;
    top: 9px;
    left: 8px;
}