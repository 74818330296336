.ReactTable .rt-thead [role="columnheader"] {
    outline: 0;
    box-shadow: none;
}

.ReactTable .rt-thead.-header{
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.ReactTable {
    border: 0;
}

.ReactTable .rt-tbody .rt-td {
    font-size: 14px;
    border-right: 0;
}

.ReactTable .rt-tbody .rt-tr:hover {
    cursor: pointer;
    background: #f9f9f9;
}

.ReactTable-readonly .rt-tbody .rt-tr:hover {
    cursor: initial !important;
    background: white !important;
}

.ReactTable .rt-thead .rt-th {
    /* border-right: 0; */
}

.ReactTable .rt-thead.-header {
    border-bottom: 0;
}

.ReactTable .-sort-asc, .ReactTable .-sort-desc {
    box-shadow: none !important;
    z-index: 998 !important;
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before,
.ReactTable .rt-th.-sort-desc .rt-resizer:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -10px;
    top: 9px;
    width: 10px;
    height: 10px;
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
    background: url('./sort-asc.svg');
}

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
    background: url('./sort-desc.svg');
}
