.Chartjs-legend-wrapper {
    padding: 0;
}

.Chartjs-legend-wrapper li {
    margin-bottom: 8px;
}

.Chartjs-legend {
    color: "#333";
    list-style: none;
    text-align: left;
    display: "flex";
    flex-direction: row;
    margin: "8px";
    position: relative;
}

.Chartjs-legend p {
    display: inline;
    margin-left: 28px;
    box-decoration-break: clone;
}

.Chartjs-bullet {
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    position: absolute;
}