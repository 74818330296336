.loadingBar-cover {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000000;
    z-index: 900;
    opacity: 0.1;
}

.loadingBar-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    z-index: 99999;
}

.loadingBar {
    display: inline-block;
    position: relative;
    background: #4A4A52;
    left: 31px;
    width: 78px;
    height: 23px;
    margin-left: -64px;
    border-radius: 4px;
    /* border: 1px solid white; */
}

.loadingBar div {
    position: absolute;
    top: 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loadingBar div:nth-child(1) {
    left: 12px;
    animation: loadingBar1 0.6s infinite;
}

.loadingBar div:nth-child(2) {
    left: 12px;
    animation: loadingBar2 0.6s infinite;
}

.loadingBar div:nth-child(3) {
    left: 32px;
    animation: loadingBar2 0.6s infinite;
}

.loadingBar div:nth-child(4) {
    left: 51px;
    animation: loadingBar3 0.6s infinite;
}

@keyframes loadingBar1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes loadingBar3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes loadingBar2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}