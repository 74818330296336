.DraftRibbon-wrapper {
	width: 85px;
  	height: 88px;
  	overflow: hidden;
  	position: absolute;
  	top: -1px;
  	right: -2px;
}

.DraftRibbon {
  	font: bold 12px Sans-Serif;
  	text-align: center;
  	transform: rotate(45deg);
  	position: relative;
  	padding: 7px 0;
  	left: -3px;
  	letter-spacing: 0.8px;
  	top: 12px;
  	width: 120px;
  	background-color: #FFED4A;
  	background-image: -webkit-gradient(linear, left top, left bottom, from(#FFED4A), to(#F6E441));
  	/* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
}

.DraftRibbon:before, .DraftRibbon:after {
  	content: "";
  	border-top: 3px solid #FFED4A;
  	border-left: 3px solid transparent;
  	border-right: 3px solid transparent;
  	position: absolute;
  	bottom: -3px;
}

.DraftRibbon:before {
  	left: 0;
}

.DraftRibbon:after {
  	right: 0;
}