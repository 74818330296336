.label-floating label {
    transition: all 0.1s ease-in-out;
    color: #002d77;
    font-weight: 600;

    font-size: 14px;
    display: inline-block;
    width: auto;
    left: -6px;
    margin-top: -22px;
    padding: 0 5px;
}

.input-readOnly {
    background: #f4f4f4 !important;
}