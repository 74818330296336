.NotificationBubble {
    position: absolute;
    top: 8px;
    left: -6px;
    border: 1px solid white;
    z-index: 100;
    background: red;
    width: 18px;
    height: 18px;
    text-align: center;
    padding-top: 1px;
    color: white;
    font-size: 12px;
    line-height: 14px;
    border-radius: 50%;
}