.ContractSection {
    margin-left: 16rem;
}

@media screen and (max-width: 1366px) {
    .ContractSection {
        margin-left: 10rem;
    }
}

@media print {
    .ContractSection {
        margin-left: 0;
    }
}