@media print {
    #root {
        position: absolute;
        left: -9999em;
    }

    .printable {
        position: absolute;
        left: 9999em;
        top: 0;
    }
}